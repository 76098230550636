import CryptoJS from 'crypto-js';
import CryptoU8array from './enc-u8array';

const key = CryptoJS.enc.Utf8.parse('jp9dzn3wg15m3l31wti538qbu38hemsd');
const iv = CryptoJS.enc.Utf8.parse('o0y70hehd961vfec');
export default {
  // 加密
  encrypt(word) {
    const srcs = CryptoJS.enc.Utf8.parse(word);

    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.NoPadding
    });
    return encrypted.toString();
  },
  // 解密
  decrypt(word) {
    var decrypt = CryptoJS.AES.decrypt(word, key, {
      iv,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.NoPadding
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
  },
  proce(buffer,imageType) {
    const view = new Uint8Array(buffer);

    // 将Uint8Array 转成 WordArray
    const contentWA = CryptoU8array.u8array.parse(view);
    // base64字符串
    const dcBase64String = contentWA.toString(CryptoJS.enc.Base64);

    // 解密
    const decryptedData = CryptoJS.AES.decrypt(dcBase64String, key, {
      iv: iv,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.NoPadding
    });
    // 把解密后的对象再转为base64编码,这步是关键,跟解密文字不同
    const d64 = decryptedData.toString(CryptoJS.enc.Base64);
    const url = `data:${imageType};base64,` + d64;
    // const url = `data:image/gif;base64,` + d64;
    return url;
  }
};
