<template>
  <view class="chat-image" :style="[{ width: width + 'px' }, { height: height + 'px' }]" >
    <img :src="srcImg" :style="[{ width: width + 'px' }, { height: height + 'px' }]" @click="showBigImage" alt="" srcset="">
  </view>
</template>

<script>
import AES from "@/common/imgaes"
export default {
  name: "ximg",
  data(){
    return {
      srcImg:""
    }
  },
  props:{
    src:{
      type: String,
      default:""
    },
    // 图片类型,0个人头像;1群组头像;2聊天图片
    type: {
      type: Number,
      default: 0
    },
    // 图片宽度
    width: {
      type: Number,
    },
    // 图片高度
    height: {
      type: Number,
    },
    // 图片半径
    radius: {
      type: Number,
    },
    // 点击全屏显示
    enlarge: {
      type: Boolean,
      default: true
    },
  },
  watch:{
    src: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue){
          this.decryptImage();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.decryptImage()
  },
  methods:{
     decryptImage(){
       var self = this
      // 判断数据是否非空
      //  var src = "https://s3.i89r4.com/aesencipher/ad/423ebd97ea68c2a6af61489f0150019f.gif"
      //  var src = "https://s3.i89r4.com/aesencipher/test1.jpg"
      var result = this.$store.getImage(self.src)
       // result = "https://s3.mysgpo.com/aesencipher/preview/234f6a0dd72e7ea9145e33c968a21d23.jpg?upload_v1.3.40"
       if (result.indexOf("aesencipher") < 0){
         self.srcImg = result
         return;
       }
      // 发送请求获取图片
      let xhr = new XMLHttpRequest()
       xhr.responseType = 'arraybuffer';
       xhr.onload = function () {
         if (xhr.status == 200){
           var imageType = xhr.getResponseHeader("Content-Type");
           var res = AES.proce(xhr.response,imageType)
           self.srcImg = res
         }
       }
      xhr.open("GET", result);
      xhr.send();
    },
    showBigImage(){
       if (!this.enlarge){
         return
       }
      this.$common.showBigImages([{url:this.srcImg }],0)
    },
  }
}
</script>

<style scoped>

.chat-image .enlarge-box {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}
.chat-image .enlarge {
  width: 400px;
  height: auto;
}
.chat-image img{
  max-width: 100%;
  max-height: 100%;
}
</style>