import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import 'dayjs/locale/zh-cn' //中文
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
import common from "./common/common"
import ximg from "./components/ximg"
const app = createApp(App)
app.config.globalProperties.$common = common
app.config.globalProperties.$store = store
app.use(ElementPlus,{locale})
app.component("ximg",ximg)
app.use(router).mount('#app')