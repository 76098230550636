function parseSysInfo(){
  try{
    var sysinfo = sessionStorage.getItem("sysInfo")
    if (sysinfo){
      return JSON.parse(sysinfo)
    }
  }catch (e){
    return {}
  }
  return {}
}
const store = {
  state: {
    userInfo:{uid:"xxx",username:"我草你妈"},
    sysInfo:parseSysInfo()
  },
  saveSysInfo(sysInfo){
    store.state.sysInfo = sysInfo
    sessionStorage.setItem("sysInfo",JSON.stringify(sysInfo))
  },
  clearSysInfo(){
    store.state.sysInfo = {}
    sessionStorage.removeItem("sysInfo")
  },
  getImage(url){
    if (url.substring(0,4) == "http" || url == "" || url.substring(0,9) == "blob:http" || url.substring(0,10) == "data:image"){
      return url
    }
    if(store.state.sysInfo.pic_cdn){
      var list = store.state.sysInfo.pic_cdn.split(";")
      if (list.length > 0){
        if (!list[0].match(/\/$/)){
          return list[0] + "/" + url
        }
        return list[0] + url
      }
    }
    return url;
  },
  getVideo(url){
    if (url.substring(0,4) == "http" || url == ""){
      return url
    }
    if(store.state.sysInfo.video_cdn){
      var list = store.state.sysInfo.video_cdn.split(";")
      if (list.length > 0){
        if (!list[0].match(/\/$/)){
          return list[0] + "/" + url
        }
        return list[0] + url
      }
    }
    return url;
  },
}
export default store
 