<template>
  <router-view/>
</template>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
body {margin:0; padding:0;background:#ffffff;}
div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,blockquote,p{padding:0; margin:0;}
li{list-style-type:none;}
img{vertical-align:top;border:0;}
ol,ul {list-style:none;}
address,cite,code,em,th {font-weight:normal; font-style:normal;}

.width_80{
  width: 80px !important;
}
.width_100{
  width: 100px !important;
}
.width_120{
  width: 120px !important;
}
.width_150{
  width: 150px !important;
}
.width_200{
  width: 200px !important;
}
.width_250{
  width: 250px !important;
}
</style>
